<template>
  <div class="quv-cont">
    <div class="form-cont">
      <div class="form-item">
        <div class="icon">
          <phone theme="two-tone" :fill="['#333' ,'#1F955B']"/>
        </div>
        <div class="input">
          <input v-model.trim="form.mobile" type="text" placeholder="请输入被保手机号码">
        </div>
      </div>
      <div class="form-item">
        <div class="icon">
          <shield theme="two-tone" :fill="['#333' ,'#1F955B']"/>
        </div>
        <div class="input">
          <input v-model.trim="form.code" type="text" placeholder="请输入手机验证码">
        </div>
        <div class="btn">
          <div class="button" @click="phoneCode">
            <span v-show="num <= 0">发送验证码</span>
            <span v-show="num > 0" class="red">{{num}}</span>
          </div>
        </div>
      </div>
      <div class="form-item" v-show="showOrder">
        <div class="icon">
          <order theme="two-tone" :fill="['#333' ,'#1F955B']"/>
        </div>
        <div class="input">
          <input v-model.trim="form.orderId" type="text" placeholder="请输入14开头的9位销售单号">
        </div>
      </div>
      <div class="form-item" v-show="showOrder">
        <div class="icon">
          <user-business theme="two-tone" :fill="['#333' ,'#1F955B']"/>
        </div>
        <div class="input">
          <input v-model.trim="form.saleMan" type="text" placeholder="请输入销售人员姓名">
        </div>
      </div>
      <div class="form-item" v-show="showOrder">
        <div class="icon">
          <sales-report theme="two-tone" :fill="['#333' ,'#1F955B']"/>
        </div>
        <div class="input">
          <input v-model.trim="form.saleMobile" type="text" placeholder="请输入销售人员手机号">
        </div>
      </div>
    </div>

    <div class="tips">
      <p>拍摄视频规范：</p>
      <p>1.1  被保手机边框全部纳入拍摄范围，无明显反光； </p>
      <p>1.2 进入拨号界面； </p>
      <p>1.3 按*#06#调出手机IMEI号；  </p>
      <p>1.4 拍摄手机后盖，停留2秒</p>
      <u>
        <a @click="showVideo = true">查看演示视频</a>
      </u>
    </div>
    
    <div class="ecode ignore-ecode">
      <div v-if="videoSrc">
        <video  class="video" :src="videoSrc" controls="controls"></video>
      </div>

      <div v-else class="ecode-un" @click="clickFile">
        <monitor theme="two-tone" size="24" :fill="['#333' ,'#ff9d0e']"/>
        <div>
          开始拍摄
        </div>
      </div>
    </div>

    <input ref="file" style="display: none;" name="file" type="file" accept="video/*" capture="camcorder" @change="updateFile" />


    <div class="fiex-cont" v-show="videoSrc">
      <div class="form-btn" @click="clickFile">重拍</div>
      <div class="form-sub" @click="sub">提交</div>
    </div>


    <div class="modo" v-if="show">
      <div class="modo-in">
        <div class="modo-icon">
          <img src="@/assets/img/success.png" alt="">
        </div>
        <div class="modo-name">您的碎屏保验机已完成</div>
        <div class="modo-title">
          可通过被保障手机号查询或发起服务
        </div>
        <div v-if="type == 2">
          <div class="modo-btn2" @click="goLink">去购买保障</div>
        </div>
      </div>
    </div>


    <div class="big-cont" v-if="showVideo">
      <video src="http://res.shanquetech.com/demo/demo.mp4" controls="controls"></video>
      <close-one @click="showVideo = false" class="big-close" theme="two-tone" :fill="['#333' ,'#1F955B']"/>
    </div>
    
  </div>
</template>

<script>
import { Monitor, CloseOne, Phone, Order, Shield, UserBusiness, SalesReport} from '@icon-park/vue'

export default {
  components: {
    Monitor,
    CloseOne,
    Phone,
    Order,
    Shield,
    UserBusiness,
    SalesReport,
  },
  data () {
    return {
      type: this.$route.query.type ? this.$route.query.type : 1,
      query: {...this.$route.query},
      showOrder: this.$route.query.channel == 'dxthn2021001' && (! this.$route.query.orderid) ? true : false, // 显示销售单号
      showVideo: false,
      videoSrc: '',
      show: false,
      num: 0, // 验证码倒计时
      form: {
        mobile: '',
        code: '',
        "contractId": this.$route.query.contractid ? this.$route.query.contractid : '', // 合同ID
        "orderId": this.$route.query.orderid ? this.$route.query.orderid : '', // 销售单号
        "channel": this.$route.query.channel ? this.$route.query.channel : '', // 渠道
        saleMan: '',
        saleMobile: '',
      },
      uuid: '',
    }
  },
  mounted () {
    if(this.$route.query.orderId){
      this.form.orderId = this.$route.query.orderId;
      this.showOrder = this.$route.query.channel == 'dxthn2021001' && (! this.$route.query.orderId) ? true : false
    }
    this.getLink()
  },
  methods: {
    goLink(){
      window.location.href = this.$init.buyLink
    },
    sub () {
      const vm = this;
      let postData = {...this.form}
      postData.uuid = this.uuid;
      if(! this.form.mobile){
        vm.$createToast({
          txt: '请输入手机号',
          type: 'warn'
        }).show()
        return
      }
      if(! this.form.code){
        vm.$createToast({
          txt: '请输入验证码',
          type: 'warn'
        }).show()
        return
      }
      if(! this.videoSrc){
        vm.$createToast({
          txt: '请上传视频',
          type: 'warn'
        }).show()
        return
      }
      if(! this.$utils.isPhone(this.form.mobile + '')){
        vm.$createToast({
          txt: '手机号格式错误',
          type: 'warn'
        }).show()
        return
      }
      if(this.showOrder && this.form.orderId == ''){
        vm.$createToast({
          txt: '请输入销售单号',
          type: 'warn'
        }).show()
        return
      }
      if(this.showOrder && this.form.saleMan == ''){
        vm.$createToast({
          txt: '请输入销售人员姓名',
          type: 'warn'
        }).show()
        return
      }
      if(this.showOrder && this.form.saleMobile == ''){
        vm.$createToast({
          txt: '请输入销售人员手机号',
          type: 'warn'
        }).show()
        return
      }

      
      vm.$store.commit('updateLoader', true);
      this.$http
        .post('/video/collect/addVideoCollect', postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.show = true;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 获取二维码地址
     */
    getLink () {
      this.uuid = this.$utils.uuid();
    },
    /**
     * 选取文件
     */
    clickFile () {
      this.$refs.file.click()
    },
    updateFile (e) {
      const file = e.target.files[0] || e.dataTransfer.files[0]
      let URL = window.URL || window.webkitURL; // 获取当前域名地址
      this.videoSrc = URL.createObjectURL(file);   // 拼接 URL + file 并转成 blob地址
      this.upFile(e)
    },
    /**
     * 文件上传
     */
    upFile (e){
      const vm = this;
      var file = this.$refs.file.files[0];
      vm.$store.commit('updateLoader', true);
      var formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("uuid", this.uuid);
      vm.$axios({
        method: 'post',
        url:  vm.$init.apis.default + "/upload/video",
        data: formData,
        timeout: 600000,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function(response) {
        vm.$store.commit('updateLoader', false);
        if (response.data.header.code == "A1000") {
          
        } else {
          vm.$createToast({
            txt: response.data.header.msg,
            type: 'warn'
          }).show()
        }
      }).catch(function(err) {
        vm.$store.commit('updateLoader', false);
        vm.$createToast({
          txt: '网络错误',
          type: 'error'
        }).show()
      });
    },
    /**
     * 发送验证码
     */
    phoneCode () {
      const vm = this;
      let postData = {
        "mobile": this.form.mobile,
      }
      if(! this.form.mobile){
        vm.$createToast({
          txt: '请输入手机号',
          type: 'warn'
        }).show()
        return
      }
      if(! this.$utils.isPhone(this.form.mobile + '')){
        vm.$createToast({
          txt: '手机号格式错误',
          type: 'warn'
        }).show()
        return
      }
      // 阻止刷
      if(vm.num > 0){
        vm.$createToast({
          txt: '请稍后再试',
          type: 'warn'
        }).show()
        return
      }
      // 倒计时
      vm.num = 60;
      let sNum = setInterval(() => {
        vm.num -= 1;
        if(vm.num == 1){
          clearInterval(sNum)
        }
      }, 1000);
      vm.$store.commit('updateLoader', true);
      this.$http
        .post('/user/sms/sendCodeForCollectVideo', postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    
  },
}
</script>

<style lang="scss" scoped>
.quv-cont{
  min-height: 100.1vh;
  background-color: #0c945a;
  box-sizing: border-box;
  padding-top: 80px;
}

.form-cont{
  width: 700px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.04);
  padding: 36px;
  // padding-bottom: 115px;
  position: relative;
}
.form-item{
  display: flex;
  margin-bottom: 22px;
  border-bottom: 1px solid #F4F4F4;
  .icon{
    padding-top: 32px;
    margin-right: 20px;
    font-size: 42px;
    img{
      display: block;
      height: 42px;
    }
  }
  .input{
    flex: 10;
    padding: 0;
    input{
      display: block;
      width: 100%;
      padding: 22px 0;
      font-size: 32px;
      line-height: 60px;
      outline: none;
    }
  }
  .btn{
    padding: 22px 0;
    margin-left: 20px;
  }
  .button{
    width: 140px;
    height: 60px;
    font-size: 24px;
    text-align: center;
    line-height: 60px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #DCDCDC;
  }
}
.form-sub{
  width: 410px;
  height: 100px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 100px;
  color: #fff;
  margin: 0 auto;
  border: 4px solid #fff;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.04);
}

.ecode{
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ignore-ecode{
  min-height: 300px;
}

.tips{
  padding: 60px 0;
  width: 600px;
  margin: 0 auto;
  color: #eee;
  font-size: 24px;
  line-height: 42px;
  p{
    // font-weight: 700;
    margin-bottom: 10px;
    // text-indent: 20px
  }
  a{
    font-size: 28px;
    color: #ff9d0e;
    font-weight: 700;
    text-align: center;
  }
}

.ecode-un{
  height: 200px;
  width: 200px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 6px;
  color: #333;
  padding-top: 40px;
  div{
    padding: 20px;
    color: #333;
  }
}

.video{
  height: 400px;
  width: 400px;
}
.fiex-cont{
  display: flex;
  align-items:center;
  justify-content:center;
  box-sizing: border-box;
  padding: 40px;
}
.form-btn{
  margin-top: 40px;
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  background: #fff;
  color: #1F955B;
  text-align: center;
}

.big-cont{
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: #fff;
  video{
    height: 100%;
    width: 100%;
  }
  .big-close{
    position: absolute;
    right: 30px;
    top: 50px;
    font-size: 100px;
    z-index: 102;
  }
}
</style>
